<template>
  <div id="app">
    <calculate-distances />
  </div>
</template>

<script>
import CalculateDistances from "./components/CalculateDistances";

export default {
  name: 'App',
  components: {
    CalculateDistances
  }
}
</script>

<style>

</style>
